import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { aboutPageHolder } from "./about.module.scss";
import Seo from "../components/components/Seo";

export default function AboutPage() {
   const data = useStaticQuery(graphql`
      query AboutQuery {
         allWpPage(filter: { title: { eq: "About" } }) {
            nodes {
               title
               content
            }
         }
      }
   `);

   const page = data.allWpPage.nodes[0];

   return (
      <article className={aboutPageHolder}>
         <Seo title="About Good Chaos" />

         <h1 className="gambarinoHeader">
            About <span className="goodWord">Good</span>
            <span className="chaosWord">Chaos</span>
         </h1>
         <div
            className="pageContent"
            dangerouslySetInnerHTML={{ __html: page.content }}
         />
      </article>
   );
}
